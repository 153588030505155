import React from "react";
import MyInput from "../../../../libs/components/MyInput";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import FormButtons from "../../../../libs/components/FormButtons";
import {Company} from "../../../../libs/models/Company";
import {Branch} from "../../../../libs/models/Branch";
import {License} from "../../../../libs/models/License";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import Requests from "../../../../libs/Requests";
import Branches from "./Branches";
import {useAppSelector} from "../../../../libs/store/hooks";
import {getStaff} from "../../../../libs/store/slices/userSlice";
import Utilities from "../../../../libs/Utilities";

interface CompanyData {
    companyId: string
    companyName: string
    branches: Branch[]
    license?: License
}

export default function Companies() {
    const [search, setSearch] = React.useState({name: ''})
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const initialCompany: Company = {companyId: "", companyName: ""}
    const [companies, setCompanies] = React.useState(Array<CompanyData>())
    const [company, setCompany] = React.useState(initialCompany)

    const [showBranches, setShowBranches] = React.useState({company: {companyId: "", companyName: ""}, branches: Array<Branch>(), show: false})

    const saveCompany = () => {
        if (company.companyName.trim().length < 3) {
            errorToast({message: 'Enter  a valid company name'})
        } else {
            showProgress("Saving company, please wait")
            Requests.saveCompany({companyId: company.companyId, companyName: company.companyName.trim()})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'Company has been saved successfully'})
                        if (company.companyId === '') {
                            setCompanies([...companies, {...company, companyId: response.data.companyId, branches: []}])
                        } else {
                            setCompanies(companies.map((aCompany) => aCompany.companyId === company.companyId ?
                                {...aCompany, companyName: company.companyName} : aCompany))
                        }
                        setCompany({...company, companyId: response.data.companyId})
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save company, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save company, please retry'})
                })
        }
    }

    const loadCompanies = () => {
        showProgress("Loading data, please wait")
        Requests.getCompanies({})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setCompanies(response.data.companyData)
                } else {
                    errorToast({message: 'Error loading companies, please retry'})
                }
            })
            .catch((error) => {
                hideProgress()
                errorToast({message: 'Error loading companies, please retry'})
            })
    }

    React.useEffect(() => {
        loadCompanies()
    }, [])

    return (
        <>
            <Branches
                show={showBranches.show} branches={showBranches.branches} company={showBranches.company}
                close={() => setShowBranches({...showBranches, show: false})}
                update={(branch: Branch) => {
                    setCompanies(companies.map((aCompany) => {
                        if (aCompany.companyId === branch.companyId) {
                            if (aCompany.branches.filter((aBranch) => aBranch.branchId === branch.branchId).length === 1) {
                                return {...aCompany, branches: aCompany.branches.map((cBranch) => cBranch.branchId === branch.branchId ? branch : cBranch)}
                            } else {
                                return {...aCompany, branches: [...aCompany.branches, branch]}
                            }
                        } else {
                            return aCompany
                        }
                    }))
                }}/>

            <div className={'filter_container'}>
                <div className={'filters'}>
                    <div>
                        <MyInput name={'name'} placeholder={'Filter by company name'} value={search.name} className={'mb-0'}
                                 onChange={(name, value) => setSearch({...search, [name]: value})}/>
                    </div>

                </div>
                <div className={'buttons'}>
                    <Button icon labelPosition='left' primary onClick={() => setCompany(initialCompany)}>
                        <Icon name='plus'/>Add New
                    </Button>

                    <Button icon labelPosition='left' primary onClick={loadCompanies}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '120px'}}>

                                        </TableHeaderCell>
                                        <TableHeaderCell style={{width: '60px'}}>No.</TableHeaderCell>
                                        <TableHeaderCell>Company Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Branches</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>License</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        companies.map((aCompany, index) =>
                                            <TableRow key={aCompany.companyId}>
                                                <TableCell style={{width: '120px'}} textAlign={'center'}>
                                                    <Button
                                                        primary size='mini' icon='edit' compact
                                                        onClick={() => setCompany({companyId: aCompany.companyId, companyName: aCompany.companyName})}/>
                                                    <Button
                                                        primary size='mini' icon='code branch' compact
                                                        disabled={!Utilities.hasRoles({
                                                            staffRoles : staff.permissions,
                                                            permissions: ['BranchesView', 'BranchesAdd', 'BranchesEdit']
                                                        })}
                                                        onClick={() => {
                                                            setShowBranches({
                                                                show    : true, branches: aCompany.branches,
                                                                company : {companyId: aCompany.companyId, companyName: aCompany.companyName},
                                                            })
                                                        }}/>
                                                </TableCell>
                                                <TableCell style={{width: '60px'}} textAlign={'center'}>{index + 1}</TableCell>
                                                <TableCell>{aCompany.companyName}</TableCell>
                                                <TableCell style={{width: '120px'}} textAlign={'center'}>{aCompany.branches.length}</TableCell>
                                                <TableCell style={{width: '150px'}}>{aCompany.license?.status ?? 'Un Licensed'}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-1 pe-0 h-100'}>
                        <div className={'form_container'}>
                            <div className={'form_input'}>
                                <MyInput name={'companyName'} placeholder={'Enter company name'} value={company.companyName} label={'Company Name'}
                                         onChange={(name, value) => setCompany({...company, [name]: value})}/>
                            </div>

                            <FormButtons
                                left={{click: () => setCompany(initialCompany)}}
                                right={{
                                    click   : saveCompany,
                                    disabled: (company.companyId === '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['CompanyAdd']})) ||
                                        (company.companyId !== '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['CompanyEdit']}))
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
