import {WorkOrderSite} from "../../../libs/models/WorkOrderSite";
import {WorkOrder, WorkOrderData} from "../../../libs/models/WorkOrder";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import React from "react";
import FormButtons from "../../../libs/components/FormButtons";
import {MySelect} from "../../../libs/components/MySelect";
import MyInput from "../../../libs/components/MyInput";
import FormTextArea from "../../../libs/components/FormTextArea";
import Utilities from "../../../libs/Utilities";
import {InputFile} from "../../../libs/models/InputFile";
import Requests from "../../../libs/Requests";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import moment from "moment/moment";
import WorkOrderSites from "./WorkOrderSites";

export default function CreateWorkOrder(params: { sites: WorkOrderSite[], updateSite: (site: WorkOrderSite) => void, }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [showSites, setShowSites] = React.useState(false)

    const initialOrder: WorkOrder = {
        assetCategory: "Other", assetCategoryOther: '', branchId: staff.branchId, description: "", estimatedCost: "", orderNo: "",
        siteId       : "", timeCreated: "", timeUpdated: "", workImage: new InputFile(""), workLevel: "Low", workOrderId: "", workStatus: 'Pending',
    }

    const [order, setOrder] = React.useState<WorkOrder>(initialOrder)
    const [workOrders, setWorkOrders] = React.useState<WorkOrderData[]>([])
    const handleChange = (name: string, value: string) => {
        setOrder({...order, [name]: value})
    }

    const saveOrder = () => {
        showProgress("Saving work order, please wait")
        Requests.saveWorkOrder({
            assetCategoryOther: order.assetCategory === 'Other' ? order.assetCategoryOther : '',
            description       : order.description.trim(),
            orderNo           : order.orderNo.trim(),
            workImage         : order.workImage.file,
            assetCategory     : order.assetCategory,
            workOrderId       : order.workOrderId,
            workLevel         : order.workLevel,
            branchId          : order.branchId,
            siteId            : order.siteId,
        })
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    let aOrder: WorkOrder = {...order, workOrderId: response.data.workOrderId}
                    if (response.data.hasOwnProperty("workImage")) {
                        aOrder = {...aOrder, workImage: new InputFile(`${Utilities.BASE_URL}${response.data.workImage}`)}
                    }
                    setOrder(initialOrder)
                    loadWorkOrders()
                    successToast({message: 'Work order data has been saved successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Could not save user, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
            })
    }

    const loadWorkOrders = () => {
        showProgress("Loading work orders, please wait")
        Requests.getMyWorkOrders()
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setWorkOrders(response.data.orders)
                    successToast({message: 'Work orders data loaded successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Error loading work orders, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading work orders, please retry'})
            })
    }

    React.useEffect(() => {
        loadWorkOrders()
    }, [])

    return (
        <>
            <WorkOrderSites show={showSites} sites={params.sites} close={() => setShowSites(false)} updateSite={params.updateSite}/>

            <div className={'filter_container'}>
                <div className={'filters'}/>

                <div className={'buttons'}>
                    <Button primary icon labelPosition='left' onClick={() => setShowSites(true)}
                            disabled={!Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['WorkOrdersSites']})}>
                        <Icon name='sitemap'/>Sites
                    </Button>

                    <Button icon labelPosition='left' primary onClick={loadWorkOrders}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Order No</TableHeaderCell>
                                        <TableHeaderCell style={{width: '150px'}}>Site Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '130px'}}>Order Date</TableHeaderCell>
                                        <TableHeaderCell style={{width: '60px'}} textAlign={'center'}>Days</TableHeaderCell>
                                        <TableHeaderCell style={{width: '60px'}}>Level</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Status</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        workOrders.map((order) =>
                                            <TableRow key={order.workOrderId}>
                                                <TableCell style={{width: '40px'}} textAlign={'center'}>
                                                    <Button
                                                        primary size='mini' icon='edit' compact
                                                        onClick={() => setOrder({
                                                            assetCategory     : order.assetCategory,
                                                            assetCategoryOther: order.assetCategoryOther,
                                                            branchId          : order.branchId,
                                                            description       : order.description.trim(),
                                                            estimatedCost     : order.estimatedCost.toString(),
                                                            orderNo           : order.orderNo,
                                                            siteId            : order.siteId,
                                                            timeCreated       : order.timeCreated,
                                                            timeUpdated       : order.timeUpdated,
                                                            workImage         : new InputFile(`${Utilities.BASE_URL}${order.workImage}`),
                                                            workLevel         : order.workLevel,
                                                            workOrderId       : order.workOrderId,
                                                            workStatus        : order.workStatus
                                                        })}/>
                                                </TableCell>
                                                <TableCell style={{width: '80px'}}>{order.orderNo}</TableCell>
                                                <TableCell style={{width: '150px'}}>{order.siteName}</TableCell>
                                                <TableCell style={{width: '130px'}}>{moment(order.timeCreated).format("lll")}</TableCell>
                                                <TableCell style={{width: '60px'}} textAlign={'center'}>{order.days}</TableCell>
                                                <TableCell style={{width: '60px'}}>{order.workLevel}</TableCell>
                                                <TableCell style={{width: '80px'}}>{order.workStatus}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-1 pe-0 h-100'}>
                        <div className={'form_container'}>
                            <div className={'form_input'}>
                                {/*
                               estimatedCost: "", : "",
              : "",
                            */}
                                <MyInput name={'orderNo'} placeholder={'Enter work oder number'} value={order.orderNo} label={'Work Order Number'}
                                         onChange={() => {
                                         }}/>

                                <MySelect
                                    name={"workLevel"} value={order.workLevel} placeholder={"Select branch name"} label={'Work Level'}
                                    onChange={(name, value) => setOrder({...order, [name]: value as string})}
                                    options={[{text: 'Low', value: 'Low'}, {text: 'Medium', value: 'Medium'}, {text: 'High', value: 'High'}]}/>

                                <MySelect
                                    name={"siteId"} value={order.siteId} placeholder={"Select work order site"} label={'Work Order Location'}
                                    onChange={(name, value) => setOrder({...order, [name]: value as string})}
                                    options={[{text: 'Select work location', value: ''}, ...params.sites.map((site) => ({
                                        text : site.siteName,
                                        value: site.siteId
                                    }))]}/>

                                <MySelect
                                    name={"assetCategory"} value={order.assetCategory} placeholder={"Select asset category"} label={'Asset Category'}
                                    onChange={(name, value) => setOrder({...order, [name]: value as string})}
                                    options={['Plumbing', 'Electrical', 'Floor', 'Ceiling', 'Wall', 'Other'].map((category) => ({
                                        text : category,
                                        value: category
                                    }))}/>

                                {
                                    order.assetCategory === 'Other' &&
                                    <MyInput name={'assetCategoryOther'} placeholder={'Enter category of work oder'}
                                             value={order.assetCategoryOther} onChange={handleChange}/>
                                }

                                <FormTextArea name={"description"} onChange={handleChange} placeholder={"Enter description of work order"}
                                              rows={6} value={order.description} label={'Description of work order'}/>

                                <div>
                                    <img src={order.workImage.file_src} alt='' style={{maxWidth: '400px', height: 'auto'}}/>
                                </div>

                                <div className="custom-file-input">
                                    <input type="file" id="fileInput" className="file-input" accept="image/*" multiple={false}
                                           onChange={(event) => {
                                               Utilities.readFileInput(event)
                                                   .then((response) => {
                                                       setOrder({...order, workImage: response});
                                                       (document.getElementById("fileInput") as HTMLInputElement).value = "";
                                                   })
                                                   .catch((error) => {
                                                       console.log(error)
                                                   })
                                           }}
                                    />
                                    <label htmlFor="file" className="file-label">Choose a file</label>
                                </div>
                            </div>

                            <FormButtons
                                left={{click: () => setOrder(initialOrder)}}
                                right={{
                                    click   : saveOrder,
                                    disabled:
                                        (order.workOrderId === '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['WorkOrdersAdd']})) ||
                                        (order.workOrderId !== '' && (!['Pending', 'Rejected'].includes(order.workStatus) || !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['WorkOrdersEdit']})))
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
