import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";
import SavePPM from "./utils/SavePPM";
import React from "react";
import {initialPPM, PPM, PPMData} from "../../../libs/models/PPM";
import Requests from "../../../libs/Requests";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import moment from "moment/moment";
import PPMServices from "./utils/PPMServices";
import {UserData} from "../../../libs/models/User";
import Utilities from "../../../libs/Utilities";

export default function CreatePPM(params: { users: UserData[] }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [ppm, setPPM] = React.useState<PPM>({...initialPPM, branchId: staff.branchId, createdBy: staff.userId})
    const [showPPMService, setShowPPMService] = React.useState<{ ppmData?: PPMData }>({})
    const [ppmData, setPpmData] = React.useState(Array<PPMData>())

    const getPPMs = () => {
        showProgress("Loading user ppm data, please wait")
        Requests.getPPMs({userId: staff.userId, branchId: staff.branchId})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setPpmData(response.data.ppm)
                    successToast({message: 'PPM data loaded successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Error loading PPM data, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading PPM data, please retry'})
            })
    }

    React.useEffect(() => {
        getPPMs()
    }, [])

    return (
        <>
            <PPMServices
                close={() => setShowPPMService({})} ppmData={showPPMService.ppmData} users={params.users}
                update={(aData) => setPpmData(ppmData.map((aPPM) => aPPM.ppm.ppmId === aData.ppm.ppmId ? aData : aPPM))}/>

            <div className={'filter_container'}>
                <div className={'filters'}/>

                <div className={'buttons'}>
                    <Button icon labelPosition='left' primary onClick={getPPMs}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '70px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '160px'}}>PPM Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '190px'}}>PPM Address</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Next Service</TableHeaderCell>
                                        <TableHeaderCell style={{width: '50px'}} textAlign={'center'}>Days</TableHeaderCell>
                                        <TableHeaderCell style={{width: '70px'}}>Frequency</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>End Date</TableHeaderCell>
                                        <TableHeaderCell style={{width: '70px'}}>Status</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        ppmData.map((ppmDatum) =>
                                            <TableRow key={ppmDatum.ppm.ppmId}>
                                                <TableCell style={{width: '70px'}} textAlign={'center'}>
                                                    <Button
                                                        primary size='mini' icon='edit' compact
                                                        onClick={() => setPPM({
                                                            ppmId           : ppmDatum.ppm.ppmId,
                                                            branchId        : ppmDatum.ppm.branchId,
                                                            ppmName         : ppmDatum.ppm.ppmName,
                                                            ppmAddress      : ppmDatum.ppm.ppmAddress,
                                                            serviceFrequency: ppmDatum.ppm.serviceFrequency,
                                                            startDate       : ppmDatum.ppm.startDate,
                                                            endDate         : ppmDatum.ppm.endDate,
                                                            description     : ppmDatum.ppm.description,
                                                            createdBy       : ppmDatum.ppm.createdBy
                                                        })}/>

                                                    <Button
                                                        disabled={!Utilities.hasRoles({
                                                            staffRoles : staff.permissions, permissions: ['CreatePPMService', 'ViewPPMServices']
                                                        })}
                                                        primary size='mini' icon='cogs' compact onClick={() => setShowPPMService({ppmData: ppmDatum})}/>
                                                </TableCell>
                                                <TableCell style={{width: '160px'}}>{ppmDatum.ppm.ppmName}</TableCell>
                                                <TableCell style={{width: '190px'}}>{ppmDatum.ppm.ppmAddress}</TableCell>
                                                <TableCell style={{width: '80px'}}>{moment(ppmDatum.nextDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '50px'}} textAlign={'center'}>{ppmDatum.daysToService}</TableCell>
                                                <TableCell style={{width: '70px'}}>{ppmDatum.ppm.serviceFrequency}</TableCell>
                                                <TableCell style={{width: '80px'}}>{moment(ppmDatum.ppm.endDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '70px'}}>{ppmDatum.ppmStatus}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-1 pe-0 h-100'}>
                        <SavePPM
                            ppm={ppm}
                            update={(ppmDatum) => {
                                if (ppm.ppmId === '') {
                                    setPpmData([ppmDatum, ...ppmData])
                                } else {
                                    setPpmData(ppmData.map((aData) => aData.ppm.ppmId === ppmDatum.ppm.ppmId ? ppmDatum : aData))
                                }
                                setPPM({...initialPPM, branchId: staff.branchId, createdBy: staff.userId})
                            }}/>
                    </div>
                </div>
            </div>
        </>
    )
}
