import {Asset} from "../../../libs/models/Asset";
import {AssetData} from "./Assets";
import FormButtons from "../../../libs/components/FormButtons";
import React from "react";
import {useToast} from "../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../libs/components/providers/ProgressProvider";
import {BranchData} from "../../../libs/models/Branch";
import {MySelect} from "../../../libs/components/MySelect";
import MyInput from "../../../libs/components/MyInput";
import Utilities from "../../../libs/Utilities";
import FormTextArea from "../../../libs/components/FormTextArea";
import {MyDateInput} from "../../../libs/components/MyDateInput";
import Requests from "../../../libs/Requests";
import {useAppSelector} from "../../../libs/store/hooks";
import {getStaff} from "../../../libs/store/slices/userSlice";

export default function SaveAsset(params: { asset: Asset, initialAsset: Asset, update: (asset: AssetData) => void, branches: Array<BranchData> }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [asset, setAsset] = React.useState(params.asset)
    const handleAsset = (name: string, value: string) => {
        setAsset({...asset, [name]: value})
    }

    const saveAsset = () => {
        const assetCost = Utilities.stripCommas(asset.acquisitionCost)
        if (asset.branchId === "") {
            errorToast({message: "Select ranch asset is attached to"})
        } else if (asset.assetName.trim().length < 3) {
            errorToast({message: "Enter a valid asset name"})
        } else if (asset.assetCode.trim().length < 3) {
            errorToast({message: "Enter a valid asset code"})
        } else if (!Utilities.isNumberValid(assetCost) || parseFloat(assetCost) < 0) {
            errorToast({message: "Enter a valid cost of acquiring asset"})
        } else if (asset.acquisitionDate === "") {
            errorToast({message: "Enter a valid date of acquiring asset"})
        } else if (asset.location.trim().length < 3) {
            errorToast({message: "Enter a valid location of asset"})
        } else {
            showProgress("Saving asset, please wait")
            const bAsset = {
                acquisitionCost: parseFloat(assetCost),
                acquisitionDate: asset.acquisitionDate,
                assetCode      : asset.assetCode.trim(),
                assetId        : asset.assetId,
                assetName      : asset.assetName.trim(),
                branchId       : asset.branchId,
                location       : asset.location.trim()
            }
            Requests.saveAsset({...bAsset})
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'User data has been saved successfully'})
                        setAsset({...bAsset, acquisitionCost: assetCost, assetId: response.data.assetId})
                        params.update({
                            ...bAsset,
                            assetId   : response.data.assetId,
                            branchName: params.branches.filter((branch) => branch.branchId === asset.branchId)[0]?.branchName
                        })
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save user, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not save user, please retry'})
                })
        }
    }

    React.useEffect(() => {
        setAsset(params.asset)
    }, [params.asset])

    return (
        <>
            <div className={'form_input'}>
                <MySelect
                    name={"branchId"} value={asset.branchId} placeholder={"Select branch name"} label={'Branch Name'}
                    onChange={(name, value) => setAsset({...asset, [name]: value as string})}
                    options={[{text: 'Select branch of asset', value: ''}, ...params.branches.map((branch) => ({
                        text : branch.branchName,
                        value: branch.branchId
                    }))]}/>

                <MyInput name={'assetName'} placeholder={'Enter asset name'} value={asset.assetName} label={'Asset Name'} onChange={handleAsset}/>

                <MyInput name={'assetCode'} placeholder={'Enter asset code'} value={asset.assetCode} label={'Asset Code'} onChange={handleAsset}/>

                <MyInput name={'acquisitionCost'} placeholder={'Enter cost of acquisition'} label={'Cost of Acquisition'} type={'text'}
                         value={Utilities.commaInput(asset.acquisitionCost)} onChange={handleAsset}/>

                <MyDateInput name={"acquisitionDate"} placeholder={"Date of Acquisition"} value={asset.acquisitionDate} label={'Date of Acquisition'}
                             maxDate={Utilities.getToday()} change={handleAsset}/>

                <FormTextArea name={"location"} onChange={handleAsset} placeholder={"Enter location of asset"} label={'Asset Location'}
                              rows={3} value={asset.location} className={'mb-0'}/>
            </div>

            <FormButtons
                left={{click: () => setAsset(params.initialAsset)}}
                right={{
                    click   : saveAsset,
                    disabled: (asset.assetId === '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['AssetsAdd']})) ||
                        (asset.assetId !== '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['AssetsEdit']}))
                }}
            />
        </>
    )
}
