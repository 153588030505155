import {WorkOrderData, WorkOrderUser, workStatus} from "../../../../libs/models/WorkOrder";
import {Button, Icon, Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import React from "react";
import Utilities from "../../../../libs/Utilities";
import moment from "moment";
import FormButtons from "../../../../libs/components/FormButtons";
import FormTextArea from "../../../../libs/components/FormTextArea";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import Requests from "../../../../libs/Requests";
import MyCheckBox from "../../../../libs/components/MyCheckBox";
import {useAppSelector} from "../../../../libs/store/hooks";
import {getStaff} from "../../../../libs/store/slices/userSlice";

export default function WorkOrderDetail(params: { workOrder?: WorkOrderData, close: () => void, update: (workOrder: WorkOrderData) => void }) {
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [showUpdate, setShowUpdate] = React.useState<{ show: boolean, status?: workStatus, comments: string }>({show: false, status: undefined, comments: ''})
    const [statusList, setStatusList] = React.useState<Array<{ text: string; value: workStatus }>>([])

    const updateStatus = () => {
        if (showUpdate.status === undefined) {
            errorToast({message: 'Select status of work order'})
        } else if (showUpdate.comments.trim().length < 5) {
            errorToast({message: 'Enter comments for updating work order status'})
        } else {
            showProgress("Updating work order status, please wait")
            Requests.saveWorkOrderStatus({
                description: showUpdate.comments.trim(),
                workOrderId: params.workOrder!!.workOrderId,
                workStatus : showUpdate.status!!
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'Work order status has been updated successfully'})
                        params.update({
                            ...params.workOrder!!,
                            workStatus: response.data.workStatus.workStatus,
                            statuses  : [...params.workOrder!!.statuses, response.data.workStatus]
                        })
                        setShowUpdate({show: false, comments: ''})
                        if (showUpdate.status === 'Approved') {
                            params.close()
                        }
                    } else {
                        errorToast({message: response.data.message ?? 'Could not update work order status, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Could not update work order status, please retry'})
                })
        }
    }

    React.useEffect(() => {
        if (params.workOrder?.workStatus === "Approved") {
            setStatusList([{text: 'Work Order Opened', value: 'Opened'}, {text: 'Order In Progress', value: 'In Progress'}, {
                text : 'Order Closed',
                value: 'Closed'
            }])
        } else if (params.workOrder?.workStatus === "Opened") {
            setStatusList([{text: 'Work Order In Progress', value: 'In Progress'}, {text: 'Order Closed', value: 'Closed'}])
        } else if (params.workOrder?.workStatus === "In Progress") {
            setStatusList([{text: 'Work Order Closed', value: 'Closed'}])
        }
    }, [params.workOrder?.workStatus])

    /*Work User*/
    const initialWorkUser: WorkOrderUser & { index: number, show: boolean } = {
        index   : -1, timeCreated: "", timeUpdated: "", userComments: "", userId: staff.userId,
        userName: `${staff.lastName} ${staff.firstName}`, userType: "Manager", show: false
    }
    const [workUser, setWorkUser] = React.useState<WorkOrderUser & { index: number, show: boolean }>(initialWorkUser)

    const saveWorkUser = () => {
        if (params.workOrder!!.users.filter((aOrder, index) => (aOrder.userId === workUser.userId) && (aOrder.userType === workUser.userType) && (workUser.index !== index)).length > 0) {
            errorToast({message: 'Work order user with selected role was already saved'})
        } else if (workUser.userComments.trim().length < 5) {
            errorToast({message: 'Enter valid user comments'})
        } else {
            showProgress("Updating work user")
            Requests.saveWorkOrderUser({
                userId      : workUser.userId,
                userType    : workUser.userType,
                userComments: workUser.userComments.trim(),
                workOrderId : params.workOrder!!.workOrderId,
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        successToast({message: 'Work order user has been saved successfully'})
                        const aUser: WorkOrderUser = {
                            timeCreated : workUser.index === -1 ? response.data.time : workUser.timeCreated,
                            timeUpdated : workUser.index === -1 ? response.data.time : "",
                            userComments: workUser.userComments,
                            userId      : workUser.userId,
                            userName    : workUser.userName,
                            userType    : workUser.userType
                        }
                        params.update({
                            ...params.workOrder!!,
                            users: workUser.index === -1 ? [...params.workOrder!!.users, aUser] :
                                params.workOrder!!.users.map((bUser, index) => index === workUser.index ? aUser : bUser)
                        })
                        setWorkUser(initialWorkUser)
                    } else {
                        errorToast({message: response.data.message ?? 'Could not save work order user, please retry'})
                    }
                })
                .catch((error) => {
                    hideProgress()
                    errorToast({message: 'Could not save work order user, please retry'})
                })
        }
    }

    return (
        <>
            {
                params.workOrder !== undefined &&
                <>
                    <Modal open={true} basic size='large' centered={false} closeOnDimmerClick={true} closeOnDocumentClick={true} onClose={params.close}>
                        <div className="modal_div">
                            <div className={'row mx-0 h-100'}>
                                <div className={'col-5 ps-0 pe-1 h-100'}>
                                    <div className={'order_details'}>
                                        {
                                            params.workOrder.workImage !== "" &&
                                            <div style={{marginBottom: '1rem'}}>
                                                <img src={`${Utilities.BASE_URL}${params.workOrder.workImage}`} alt='' style={{width: '100%', height: 'auto'}}/>
                                            </div>
                                        }

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Order Number</div>
                                            <div className={'detailData'}>{params.workOrder.orderNo}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Created By</div>
                                            <div className={'detailData'}>{params.workOrder.createdBy}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Time Created</div>
                                            <div className={'detailData'}>{moment(params.workOrder.timeCreated).format('lll')}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Days Since Post</div>
                                            <div className={'detailData'}>{params.workOrder.days}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Work Level</div>
                                            <div className={'detailData'}>{params.workOrder.workLevel}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Work Status</div>
                                            <div className={'detailData'}>{params.workOrder.workStatus}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Asset Category</div>
                                            <div className={'detailData'}>
                                                {params.workOrder.assetCategory === 'Other' ? params.workOrder.assetCategoryOther : params.workOrder.assetCategory}
                                            </div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Work Location</div>
                                            <div className={'detailData'}>{params.workOrder.siteName}</div>
                                        </div>

                                        <div className={'orderDetail'}>
                                            <div className={'detailHeader'}>Estimated Cost</div>
                                            <div className={'detailData'}>{params.workOrder.estimatedCost.toLocaleString()}</div>
                                        </div>

                                        {
                                            ['Pending', 'Rejected'].includes(params.workOrder.workStatus) &&
                                            <div style={{textAlign: 'center', marginTop: '5px'}}>
                                                <Button positive icon labelPosition='left' size={'mini'}
                                                        onClick={() => setShowUpdate({show: true, status: 'Approved', comments: ''})}
                                                        disabled={!Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['WorkOrdersApprove']})}>
                                                    <Icon name='check'/>Approve Order
                                                </Button>

                                                <Button negative icon labelPosition='left' size={'mini'}
                                                        onClick={() => setShowUpdate({show: true, status: 'Rejected', comments: ''})}
                                                        disabled={!Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['WorkOrdersApprove']})}>
                                                    <Icon name='cancel'/>Reject Order
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className={'col-7 ps-1 pe-0 h-100'}>
                                    {/*Work Order Users*/}
                                    <div className={'table_container'} style={{height: 'auto', maxHeight: '40vh'}}>
                                        <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                                    <TableHeaderCell style={{width: '80px'}}>User Type</TableHeaderCell>
                                                    <TableHeaderCell style={{width: '130px'}}>Full Name</TableHeaderCell>
                                                    <TableHeaderCell style={{width: '250px'}}>Comments</TableHeaderCell>
                                                </TableRow>
                                            </TableHeader>

                                            <TableBody>
                                                {
                                                    params.workOrder.users.map((aUser, index) =>
                                                        <TableRow key={index}>
                                                            <TableCell style={{width: '40px'}}>
                                                                <Button primary size='mini' icon='edit' compact
                                                                        onClick={() => setWorkUser({...aUser, show: true, index: index})}/>
                                                            </TableCell>
                                                            <TableCell style={{width: '80px'}}>{aUser.userType}</TableCell>
                                                            <TableCell style={{width: '130px'}}>{aUser.userName}</TableCell>
                                                            <TableCell style={{width: '250px'}}>{aUser.userComments}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div style={{textAlign: 'center', margin: '3px 5px 10px 5px'}}>
                                        <Button primary icon labelPosition='left' size={'mini'}
                                                onClick={() => setWorkUser({...initialWorkUser, show: true})}>
                                            <Icon name='add'/>Add Another Work Order User
                                        </Button>
                                    </div>

                                    {/*Work Order Statuses*/}
                                    <div className={'table_container'} style={{height: 'auto', maxHeight: '40vh'}}>
                                        <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHeaderCell style={{width: '70px'}}>Status</TableHeaderCell>
                                                    <TableHeaderCell style={{width: '130px'}}>Time Updated</TableHeaderCell>
                                                    <TableHeaderCell style={{width: '130px'}}>Updated By</TableHeaderCell>
                                                    <TableHeaderCell style={{width: '200px'}}>Comments</TableHeaderCell>
                                                </TableRow>
                                            </TableHeader>

                                            <TableBody>
                                                {
                                                    params.workOrder.statuses.map((aStatus, index) =>
                                                        <TableRow key={index}>
                                                            <TableCell style={{width: '70px'}}>{aStatus.workStatus}</TableCell>
                                                            <TableCell style={{width: '130px'}}>{moment(aStatus.timeCreated).format('lll')}</TableCell>
                                                            <TableCell style={{width: '130px'}}>{aStatus.userName}</TableCell>
                                                            <TableCell style={{width: '200px'}}>{aStatus.description}</TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        ['Approved', 'Opened', 'In Progress'].includes(params.workOrder.workStatus) &&
                                        <div style={{textAlign: 'center', margin: '3px 5px 0px 5px'}}>
                                            <Button primary icon labelPosition='left' size={'mini'}
                                                    onClick={() => setShowUpdate({show: true, status: 'Approved', comments: ''})}>
                                                <Icon name='edit'/>Update Work Order Status
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal open={showUpdate.show} basic size='mini' centered={false}>
                        <div className="modal_div">
                            {
                                showUpdate.status === 'Approved' ? <div className="form_header">Approve Work Order</div> :
                                    (showUpdate.status === 'Rejected' ? <div className="form_header">Reject Work Order</div> :
                                        <div className="form_header">Update Work Order Status</div>)
                            }
                            <div className="form_container">
                                <div className={'form_input'}>
                                    {
                                        !['Pending', 'Rejected'].includes(params.workOrder.workStatus) &&
                                        <>
                                            {
                                                statusList.map((data) =>
                                                    <div key={data.value} className={'mb-2'}>
                                                        <MyCheckBox
                                                            checked={showUpdate.status === data.value} label={data.text}
                                                            onChange={(checked) => {
                                                                if (checked) {
                                                                    setShowUpdate({...showUpdate, status: data.value})
                                                                }
                                                            }}/>
                                                    </div>
                                                )
                                            }
                                        </>
                                    }

                                    <FormTextArea
                                        name={'comments'} placeholder={'Insert your comments here'} rows={4} value={showUpdate.comments}
                                        label={'Status Comments'} className={'mb-0 mt-3'}
                                        onChange={(name, value) => setShowUpdate({...showUpdate, [name]: value})}/>
                                </div>

                                <FormButtons
                                    left={{text: 'Close Window', click: () => setShowUpdate({show: false, comments: ''})}}
                                    right={{click: updateStatus}}/>
                            </div>
                        </div>
                    </Modal>

                    {/*WORK USER*/}
                    <Modal open={workUser.show} basic size='mini' centered={false}>
                        <div className="modal_div">
                            <div className="form_container">
                                <div className={'form_input'}>
                                    {
                                        ['Manager', 'Technician'].map((data) =>
                                            <div key={data} className={'mb-2'}>
                                                <MyCheckBox
                                                    checked={workUser.userType === data} label={data}
                                                    onChange={(checked) => {
                                                        if (checked) {
                                                            setWorkUser({...workUser, userType: data as 'Manager' | 'Technician'})
                                                        }
                                                    }}/>
                                            </div>
                                        )
                                    }

                                    <FormTextArea
                                        name={'userComments'} placeholder={'Insert your comments here'} rows={4} value={workUser.userComments}
                                        label={'User Comments'} className={'mb-0 mt-3'}
                                        onChange={(name, value) => setWorkUser({...workUser, [name]: value})}/>
                                </div>

                                <FormButtons
                                    left={{text: 'Close Window', click: () => setWorkUser(initialWorkUser)}}
                                    right={{click: saveWorkUser}}/>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </>
    )
}
