import {PPM, PPMData} from "../../../../libs/models/PPM";
import React from "react";
import {Modal} from "semantic-ui-react";
import SavePPM from "./SavePPM";

export default function EditPPM(params: { ppm: PPM, update: (ppm: PPMData) => void, close: () => void, show: boolean }) {
    return (
        <Modal open={params.show} basic size='small' centered={false}>
            <div className="modal_div full">
                <div className="form_header">Edit PPM</div>

                <div className={'row mx-0'} style={{flex: 1}}>
                    <div className={'col-12 ps-0 pe-1 h-100'}>
                        <SavePPM ppm={params.ppm} update={params.update} close={params.close}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
