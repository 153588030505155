export type Pages =
    'Home' |
    'Admin Companies' | 'Admin Licenses' | 'Admin Users ' |
    'Assets' |
    'Create WorkOrders' | 'Approve WorkOrders' | 'WorkOrders-Approved' | 'WorkOrders-Open' | 'WorkOrders-InProgress' | 'WorkOrders-Closed' |
    'WorkOrders-All' |
    'Create PPM' | 'View PPMs' |
    'Reports-Work-Orders-Date' | 'Reports-Work-Orders-Open' | 'Reports-Work-Orders-Upcoming' | 'Reports-Work-Orders-Pending' |
    'Reports-Work-Orders-Overdue' | 'Reports-Work-Orders-Technician' | 'Reports-Work-Orders-Site' |
    'Reports-PPM-Closed' | 'Reports-PPM-Overdue' | 'Reports-PPM-Technician' | 'Reports-PPM-Site' | 'Reports-PPM-All'

export const GroupRoles = {
    'AssetsView' : 'View Assets',
    'AssetsEdit' : 'Edit Assets',
    'AssetsAdd'  : 'Create New Assets',
    'AssetsPrint': 'Print Assets List',

    'AdminRoles': 'Has administrative roles',

    'CompanyView': 'View Companies',
    'CompanyEdit': 'Edit Companies',
    'CompanyAdd' : 'Create New Companies',

    'BranchesEdit': 'Edit Branches',
    'BranchesAdd' : 'Add Branches',
    'BranchesView': 'View Branches',

    'LicensesView': 'View Licenses',
    'LicenseEdit' : 'Edit Licenses',
    'LicenseAdd'  : 'Add Licenses',

    'GroupsView': 'View Groups',
    'GroupEdit' : 'Edit Groups',
    'GroupAdd'  : 'Add Groups',

    'UsersView'    : 'View Users',
    'UserEdit'     : 'Edit Users',
    'UserAdd'      : 'Add Users',
    'PasswordReset': 'Reset User Passwords',

    'WorkOrdersView'   : 'View WorkOrders',
    'WorkOrdersAdd'    : 'Create WorkOrders',
    'WorkOrdersEdit'   : 'Edit WorkOrders',
    'WorkOrdersSites'  : 'Manage WorkOrder Sites',
    'WorkOrdersApprove': 'Approve or Reject WorkOrders',

    'CreatePPM'       : 'Create Preventive Planned Managements',
    'EditPPM'         : 'Edit Preventive Planned Managements',
    'ViewPPM'         : 'View Preventive Planned Managements',
    'CreatePPMService': 'Create Preventive Planned Management Service',
    'ViewPPMServices' : 'View Preventive Planned Management Services',

    'CreateReports': 'Create Reports',
}

export type ModuleType = 'Assets Managements' | 'Work Orders' | 'Preventive Planned Managements' | 'Admin Roles' |
    'Companies and Branches Management' | 'Users and Groups Management' | 'Licenses Management' | 'Create and Export Reports'
type RoleGroup = {
    [key in ModuleType]: (keyof typeof GroupRoles)[]
}
export const adminRoles: (keyof typeof GroupRoles)[] = ['CompanyView', 'CompanyEdit', 'CompanyAdd', 'LicensesView', 'LicenseEdit', 'LicenseAdd']

export const RoleGroups: RoleGroup = {
    'Assets Managements'            : ['AssetsView', "AssetsEdit", 'AssetsAdd', 'AssetsPrint'],
    'Work Orders'                   : ['WorkOrdersAdd', 'WorkOrdersEdit', 'WorkOrdersApprove', 'WorkOrdersView', 'WorkOrdersSites'],
    'Preventive Planned Managements': ['CreatePPM', "EditPPM", 'ViewPPM', 'CreatePPMService', 'ViewPPMServices'],
    'Create and Export Reports'     : ['CreateReports'],

    'Admin Roles'                      : ['AdminRoles'],
    'Companies and Branches Management': ['CompanyView', 'CompanyEdit', 'CompanyAdd', 'BranchesView', 'BranchesEdit', 'BranchesAdd'],
    'Users and Groups Management'      : ['GroupsView', 'GroupAdd', 'GroupEdit', 'UsersView', 'UserAdd', 'UserEdit', 'PasswordReset'],
    'Licenses Management'              : ['LicensesView', 'LicenseEdit', 'LicenseAdd'],
}
