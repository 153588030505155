import {Checkbox} from "semantic-ui-react";
import React from "react";

export default function MyCheckBox(params: { label: string, checked: boolean, onChange: (checked: boolean) => void, disabled?: boolean }) {

    return (
        <Checkbox label={params.label} onChange={(event, data) => params.onChange(data.checked as boolean)}
                  className={'check_box'} checked={params.checked} disabled={params.disabled}/>
    )
}
