import Utilities from "../Utilities";

export interface PPM {
    ppmId: string
    branchId: string
    ppmName: string
    ppmAddress: string
    serviceFrequency: 'Monthly' | 'Quarterly' | 'Bi Annually' | 'Annually'
    startDate: string
    endDate: string
    description: string
    createdBy: string
}

export const initialPPM: PPM = {
    ppmId    : "", branchId: "", ppmName: "", ppmAddress: "", serviceFrequency: 'Monthly',
    startDate: Utilities.getToday(), endDate: "", description: "", createdBy: ""
}

export interface PPMService {
    ppmServiceId: string
    serviceDate: string
    userId: string
    serviceNotes: string
    technicianName: string
    timeUpdated: string
    technicianId: string
    serviceStatus: 'Pending' | 'Extended' | 'Completed'
}

export interface PPMData {
    ppm: {
        branchId: string
        branchName: string
        createdBy: string
        username: string
        description: string
        endDate: string
        ppmAddress: string
        ppmId: string
        ppmName: string
        serviceFrequency: 'Monthly' | 'Quarterly' | 'Bi Annually' | 'Annually'
        startDate: string
        timeCreated: string
        timeUpdated: string
    }

    daysToService: number
    services: PPMService[]
    nextService: PPMService
    nextDate: string
    ppmStatus: 'Active' | 'Expired'
}
