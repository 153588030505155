import {GroupRoles, Pages, RoleGroups} from "../models/Types";
import {useAppSelector} from "../store/hooks";
import {getStaff} from "../store/slices/userSlice";
import Utilities from "../Utilities";

export default function NavHeader(params: { page: Pages, setPage: (page: Pages) => void }) {
    const staff = useAppSelector(getStaff)

    const hasRoles = (groups: (keyof typeof RoleGroups)[]) => {
        let permissions: (keyof typeof GroupRoles)[] = []
        groups.forEach((group) => {
            RoleGroups[group].forEach(role => {
                permissions.push(role)
            })
        })
        return Utilities.hasRoles({staffRoles: staff.permissions, permissions: permissions}) ? '' : 'd-none'
    }

    const hasRole = (permissions: (keyof typeof GroupRoles)[]) => {
        return Utilities.hasRoles({staffRoles: staff.permissions, permissions: permissions}) ? '' : 'd-none'
    }

    return (
        <>
            <nav className="nav-area">
                <ul className="menu">
                    <li className={`${params.page === 'Home' ? 'active' : ''}`}>
                        <span className={'link'} onClick={() => params.setPage('Home')}>Home</span>
                    </li>

                    <li className={`${params.page === 'Assets' ? 'active' : ''} ${hasRoles(['Assets Managements'])}`}>
                        <span className={'link'} onClick={() => params.setPage('Assets')}>Assets</span>
                    </li>

                    <li className={`${params.page.includes("WorkOrder") ? 'active' : ''} ${hasRoles(['Work Orders'])}`}>
                        <span className={'link'}>Work Orders</span>
                        <ul>
                            <li className={`${hasRole(['WorkOrdersAdd', 'WorkOrdersSites', 'WorkOrdersEdit'])}`}>
                                <span className={'link'} onClick={() => params.setPage('Create WorkOrders')}>Create Work Orders</span>
                            </li>
                            <li className={`${hasRole(['WorkOrdersApprove'])}`}>
                                <span className={'link'} onClick={() => params.setPage('Approve WorkOrders')}>Approve Work Orders</span>
                            </li>
                            <li className={`${hasRole(['WorkOrdersView'])}`}>
                                <span className={'link'}>List of Work Orders</span>
                                <ul>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-Approved')}>Approved, Not Started</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-Open')}>Open Orders</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-InProgress')}>Work In Progress</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-Closed')}>Closed Orders</span></li>
                                    <li><span className={'link'} onClick={() => params.setPage('WorkOrders-All')}>All Orders</span></li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li className={`${['Create PPM', 'View PPMs'].includes(params.page) ? 'active' : ''} ${hasRoles(['Preventive Planned Managements'])}`}>
                        <span className={'link'}>PPMs</span>
                        <ul>
                            <li className={`${hasRole(['CreatePPM'])}`}>
                                <span className={'link'} onClick={() => params.setPage('Create PPM')}>Create PPMs</span>
                            </li>
                            <li className={`${hasRole(['ViewPPM', 'ViewPPMServices', 'EditPPM', 'CreatePPMService'])}`}>
                                <span className={'link'} onClick={() => params.setPage('View PPMs')}>View PPMs</span>
                            </li>
                        </ul>
                    </li>

                    <li className={`${params.page.includes('Reports-') ? 'active' : ''} ${hasRoles(['Create and Export Reports'])}`}>
                        <span className={'link'}>Reports</span>
                        <ul>
                            <li><span className={'link'}
                                      onClick={() => params.setPage('Reports-Work-Orders-Date')}>Work orders per day, week, month, year</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Open')}>Open Work Orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Upcoming')}>Upcoming Work Orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Pending')}>Pending Work Orders</span></li>
                            <li><span className={'link'}
                                      onClick={() => params.setPage('Reports-Work-Orders-Overdue')}>Overdue / non completed work orders</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Technician')}>Work orders per technician</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-Work-Orders-Site')}>Work orders per location or site</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Closed')}>PPMs Closed</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Overdue')}>PPMs Overdue</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Technician')}>Technician PPMs</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-Site')}>Site PPMs</span></li>
                            <li><span className={'link'} onClick={() => params.setPage('Reports-PPM-All')}>All PPMs</span></li>
                        </ul>
                    </li>

                    <li className={`${params.page.includes('Admin') ? 'active' : ''} ${hasRoles(['Admin Roles', 'Companies and Branches Management', 'Users and Groups Management', 'Licenses Management'])}`}>
                        <span className={'link'}>Administration</span>
                        <ul>
                            <li className={`${params.page === 'Admin Companies' ? 'active' : ''} ${hasRoles(['Companies and Branches Management'])}`}>
                                <span className={'link'} onClick={() => params.setPage('Admin Companies')}>Manage Companies</span>
                            </li>
                            <li className={`${params.page === 'Admin Licenses' ? 'active' : ''} ${hasRoles(['Licenses Management'])}`}>
                                <span className={'link'} onClick={() => params.setPage('Admin Licenses')}>Manage Licenses</span>
                            </li>
                            <li className={`${params.page === 'Admin Users ' ? 'active' : ''} ${hasRoles(['Admin Roles', 'Users and Groups Management'])}`}>
                                <span className={'link'} onClick={() => params.setPage('Admin Users ')}>Manage Users</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </>
    )
}
