import React from "react";
import {useToast} from "../../../../libs/components/providers/ToastProvider";
import {useProgress} from "../../../../libs/components/providers/ProgressProvider";
import {Button, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from "semantic-ui-react";
import {License} from "../../../../libs/models/License";
import MyInput from "../../../../libs/components/MyInput";
import FormButtons from "../../../../libs/components/FormButtons";
import {Company} from "../../../../libs/models/Company";
import {BranchData} from "../../../../libs/models/Branch";
import Requests from "../../../../libs/Requests";
import {MySelect} from "../../../../libs/components/MySelect";
import {MyDateInput} from "../../../../libs/components/MyDateInput";
import Utilities from "../../../../libs/Utilities";
import moment from "moment";
import {useAppSelector} from "../../../../libs/store/hooks";
import {getStaff} from "../../../../libs/store/slices/userSlice";


export default function Licenses() {
    const initialLicense: License = {
        branchId  : "",
        companyId : "",
        expiryDate: "",
        licenseId : "",
        licenseKey: "",
        startDate : Utilities.getToday(),
        status    : 'Active'
    }
    const staff = useAppSelector(getStaff)

    const {errorToast, successToast} = useToast()
    const {showProgress, hideProgress} = useProgress()

    const [license, setLicense] = React.useState(initialLicense)
    const [licenses, setLicenses] = React.useState(Array<License>())

    const [companies, setCompanies] = React.useState(Array<Company>())
    const [branches, setBranches] = React.useState(Array<BranchData>())

    const handleChange = (name: string, value: string) => {
        setLicense({...license, [name]: value})
    }

    const saveLicense = () => {
        const licenseNo = license.licenseKey.replace(/\s+/g, '');
        if (license.companyId === "") {
            errorToast({message: ""})
        } else if (licenseNo.length !== 16) {
            errorToast({message: ""})
        } else if (license.startDate === "") {
            errorToast({message: ""})
        } else if (license.expiryDate === "") {
            errorToast({message: ""})
        } else {
            showProgress("Saving license, please wait")
            Requests.saveLicense({
                branchId  : license.branchId,
                companyId : license.companyId,
                expiryDate: license.expiryDate,
                licenseId : license.licenseId,
                licenseKey: licenseNo,
                startDate : license.startDate
            })
                .then((response) => {
                    hideProgress()
                    if (response.data.status) {
                        const aLicense = {...license, licenseId: response.data.licenseId, licenseNo: licenseNo}
                        if (license.licenseId === "") {
                            setLicenses([aLicense, ...licenses])
                        } else {
                            setLicenses(licenses.map((bLicense) => aLicense.licenseId === bLicense.licenseId ? aLicense : bLicense))
                        }
                        successToast({message: 'License saved successfully'})
                        setLicense(aLicense)
                    } else {
                        errorToast({message: response.data.message ?? 'Error saving license, please retry'})
                    }
                })
                .catch(() => {
                    hideProgress()
                    errorToast({message: 'Error saving licenses, please retry'})
                })
        }
    }

    const loadLicenses = () => {
        showProgress("Loading licenses, please wait")
        Requests.getLicenses({})
            .then((response) => {
                hideProgress()
                if (response.data.status) {
                    setCompanies(response.data.companies)
                    setLicenses(response.data.licenses)
                    setBranches(response.data.branches)
                    successToast({message: 'Licenses loaded successfully'})
                } else {
                    errorToast({message: response.data.message ?? 'Error loading licenses, please retry'})
                }
            })
            .catch(() => {
                hideProgress()
                errorToast({message: 'Error loading licenses, please retry'})
            })
    }

    React.useEffect(() => {
        loadLicenses()
    }, [])

    return (
        <>
            <div className={'filter_container'}>
                <div className={'filters'}></div>

                <div className={'buttons'}>
                    <Button icon labelPosition='left' primary onClick={() => setLicense(initialLicense)}>
                        <Icon name='plus'/>Add New
                    </Button>

                    <Button icon labelPosition='left' primary onClick={loadLicenses}>
                        <Icon name='search'/>Search
                    </Button>
                </div>
            </div>

            <div className={'module_content'}>
                <div className={'row m-0 h-100'}>
                    <div className={'col-8 ps-0 pe-1 h-100'}>
                        <div className={'table_container'}>
                            <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell style={{width: '40px'}}></TableHeaderCell>
                                        <TableHeaderCell style={{width: '120px'}}>Company Name</TableHeaderCell>
                                        <TableHeaderCell style={{width: '180px'}}>License Number</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Start Date</TableHeaderCell>
                                        <TableHeaderCell style={{width: '80px'}}>Expiry Date</TableHeaderCell>
                                        <TableHeaderCell style={{width: '60px'}}>Status</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    {
                                        licenses.map((aLicense) =>
                                            <TableRow>
                                                <TableCell style={{width: '40px'}} textAlign={'center'}>
                                                    <Button primary size='mini' icon='edit' compact onClick={() => setLicense(aLicense)}/>
                                                </TableCell>
                                                <TableCell style={{width: '120px'}}>
                                                    {companies.filter((company) => company.companyId === aLicense.companyId)[0]?.companyName}
                                                </TableCell>
                                                <TableCell style={{width: '180px'}}>{Utilities.formatLicense(aLicense.licenseKey)}</TableCell>
                                                <TableCell style={{width: '80px'}}>{moment(aLicense.startDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '80px'}}>{moment(aLicense.expiryDate).format("ll")}</TableCell>
                                                <TableCell style={{width: '60px'}}>Status</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className={'col-4 ps-1 pe-0 h-100'}>
                        <div className={'form_container'}>
                            <div className={'form_input'}>
                                <MySelect
                                    name={"companyId"} value={license.companyId} placeholder={"Select company name"} label={'Company Name'}
                                    onChange={(name, value) => setLicense({...license, [name]: value as string, branchId: ''})}
                                    options={[
                                        {text: 'Select a company', value: ''},
                                        ...companies.map((company) => ({text: company.companyName, value: company.companyId}))
                                    ]}/>

                                <MySelect
                                    name={"branchId"} value={license.branchId} placeholder={"Select branch name"} label={'Branch Name'}
                                    onChange={(name, value) => setLicense({...license, [name]: value as string})}
                                    options={[
                                        {text: 'Applicable to all branches', value: ''},
                                        ...branches
                                            .filter((branch) => branch.companyId === license.companyId)
                                            .map((branch) => ({text: branch.branchName, value: branch.branchId}))
                                    ]}/>

                                <MyInput
                                    name={'licenseNo'} placeholder={'Enter License Number'} value={license.licenseKey} label={'License Number'}
                                    onChange={(name, value) => {
                                        const formattedLicense = Utilities.formatLicense(value)
                                        if (/^\d*$/.test(formattedLicense) && formattedLicense.length <= 19) {
                                            handleChange(name, formattedLicense)
                                        }
                                    }}/>

                                <MyDateInput value={license.startDate} name={'startDate'} placeholder={'Enter start date'} label={'Start Date'}
                                             change={handleChange} minDate={Utilities.getToday()} maxDate={license.expiryDate}/>

                                <MyDateInput value={license.expiryDate} name={'expiryDate'} placeholder={'Enter expiry date'} label={'Expiry Date'}
                                             change={handleChange} minDate={license.startDate}/>

                                <MySelect
                                    name={"status"} value={license.status} placeholder={"Select license status"} label={'License Status'}
                                    onChange={(name, value) => setLicense({...license, [name]: value as string})}
                                    options={[{text: 'Active', value: 'Active'}, {text: 'Revoked', value: 'Revoked'},]}/>
                            </div>

                            <FormButtons
                                left={{click: () => setLicense(initialLicense)}}
                                right={{
                                    click   : saveLicense,
                                    disabled: (license.licenseId === '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['LicenseAdd']})) ||
                                        (license.licenseId !== '' && !Utilities.hasRoles({staffRoles: staff.permissions, permissions: ['LicenseEdit']}))
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
